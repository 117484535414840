class Client {
  constructor() {
    const csrfToken = document.querySelector('[name="csrf-token"]').content

    this.csrfToken = csrfToken
  }

  async updateFulFillmentOption(id, locationID, attrs) {
    const url = `/locations/${locationID}/fulfillment_options/${id}`

    this.patch(url, attrs)
  }

  async updateScheduledMenuChange(scheduledMenuSet) {
    const url = `/businesses/0`

    return await this.patch(url, { scheduled_menu_set: scheduledMenuSet })
  }

  async upsertScheduledCategoryChanges(pendingChanges, categoryID) {
    const url = `/categories/${categoryID}/scheduled_changes`

    return await this.patch(url, { pending_changes: pendingChanges })
  }

  async updateClaimOrderBy(locationID, selected) {
    const url = `/locations/${locationID}/claim_order_by`

    return await this.patch(url, { selected })
  }

  async updateTaxExemptions(taxExemptions) {
    const url = '/tax_exemptions/0'

    return await this.patch(url, { taxExemptions })
  }

  async subscribe(productID) {
    const url = '/billings/create_checkout_session'

    const resp = await this.post(url, { productID })

    return await resp.json()
  }

  async getSquareDevicePairingCode(kioskID) {
    const url = `/settings/square_device_pairing_code?kiosk_id=${kioskID}`

    return await this.get(url)
  }

  async syncPartnerMenu(locationID, secretID, partnerName = 'gloria_foods') {
    const url = `/menus/partner`

    const resp = await this.post(url, {locationID, partner_name: partnerName})

    return await resp.json()
  }

  async updateLocation(locationID, attributes) {
    const url = `/locations/${locationID}`

    return await this.patch(url, {
      attributes
    })
  }

  async createReport(locationID, startTime, endTime, orderID) {
    const url = `/locations/${locationID}/reports`

    const resp = await this.post(url, {startTime, endTime, orderID})

    return await resp.json()
  }

  async createUserSession(loginValues) {
    const url = '/users/sign_in'

    const resp = await this.post(url, loginValues)

    return await resp.json()
  }

  // TODO: rename to revokeGamsaSecret or something
  async revokeKioskToken(locationID, kioskID) {
    const url = `/locations/${locationID}/kiosks/${kioskID}`

    await this.delete(url)
  }

  async createLocationSecret(locationID, secret, purpose) {
    const url = `/locations/${locationID}/secrets`

    const resp = await this.post(url, {secret, purpose})

    return await resp.json()
}

  async revokeBusinessSecret(secretType) {
    const url = `/secrets/${secretType}`

    await this.delete(url)
  }

  async updateItemOrder(categoryID, itemOrderIDs) {
    const url = `/categories/${categoryID}/items/update_order`

    await this.patch(url, { categoryID, itemOrderIDs })
  }

  async updateCategoryOrder(menuID, categoryOrderIDs) {
    const url = `/menus/${menuID}/categories/update_order`

    await this.patch(url, { menuID, categoryOrderIDs })
  }

  async editChoiceOrder(optionID, choiceOrderIDs) {
    const url = `/options/${optionID}/choices/update_order`

    await this.patch(url, { choice_order_IDs: choiceOrderIDs })
  }

  async editOptionOrder(itemID, optionOrderIDs) {
    const url = `/items/${itemID}/options/update_order`

    await this.patch(url, { option_order_ids: optionOrderIDs })
  }

  async createOption(option) {
    const url = `/items/${option.item_id}/options`
    const resp = await this.post(url, option)

    return await resp.json()
  }

  async updateOption(option) {
    const url = `/items/${option.item_id}/options/${option.option_id}`
    const resp = await this.patch(url, option)

    return await resp.json()
  }

  async upsertCategoryNames(categories, menuID) {
    const url = `/menus/${menuID}/categories/upsert_names`
    const resp = await this.patch(url, categories)

    return await resp.json()
  }

  async createKioskToken(locationID, name) {
    const url = `/locations/${locationID}/kiosks`

    const resp = await this.post(url, {name})

    return await resp.json()
  }
  async createPaymentProcessorAccount(info, paymentProcessor = 'stripe') {
    const url = '/businesses'

    const resp = await this.post(url, { info,  paymentProcessor})

    return await resp.json()
  }

  async uploadPhoto({
    imageFile,
    targetType,
    targetID,
  }) {
    const url = `/photos?type=${targetType}&id=${targetID}`
    let formData = new FormData();
    formData.append("image", imageFile);

    const resp = await this.upload(url, formData)

    return await resp.json()
  }

  async addItem(categoryID) {
    const url = `/categories/${categoryID}/items`

    const resp = await this.post(url)
    return await resp.json()
  }

  async updateItem(item, selectedOptions) {
    const url = `/items/${item.id}`

    const resp = await this.patch(url, {
      item,
      selectedOptions,
    })

    return resp
  }

  async createCategory(menuID) {
    const url = `/menus/${menuID}/categories`

    const resp = await this.post(url)
    return await resp.json()
  }

  async deleteCategory(categoryID) {
    const url = `/categories/${categoryID}`

    return await this.delete(url)
  }

  async deleteItem(itemID) {
    const url = `/items/${itemID}`

    return await this.delete(url)
  }

  async deleteOption(optionID) {
    const url = `/options/${optionID}`

    return await this.delete(url)
  }

  async deleteChoice(choiceID) {
    const url = `/choices/${choiceID}`

    return await this.delete(url)
  }

  async patch(url, body) {
    const resp = await fetch(url, {
      method: 'PATCH',
      headers: {
        'X-CSRF-Token': this.csrfToken,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body),
    })

    return resp
  }

  async upload(url, formData) {
    const resp = await fetch(url, {
      method: 'POST',
      headers: {
        'X-CSRF-Token': this.csrfToken,
      },
      body: formData
    })

    return resp
  }

  async post(url, body = {}) {
    const resp = await fetch(url, {
      method: 'POST',
      headers: {
        'X-CSRF-Token': this.csrfToken,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body),
    })

    return await resp
  }

  async get(url) {
    const resp = await fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      }
    })

    return await resp.json()
  }

  async delete(url) {
    const resp = await fetch(url, {
      method: 'DELETE',
      headers: {
        'X-CSRF-Token': this.csrfToken,
      },
    })

    return resp
  }
}

export default Client
